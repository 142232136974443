$primary-color: #f7a194;
$secondary-color: #8e5c5c;
$active-color: #66baff;
$highlight-color: #ffcec5;

@import 'animate.css';
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;700;800&family=Paytone+One&family=Roboto+Mono&display=swap');
input,
textarea {
  font-family: 'Roboto Mono';
}
