@use '../../App';

$hoverColor: #503d3d;

.home-page {
  .subtitle-text {
    overflow-wrap: normal;
  }

  .text-zone {
    width: 50%;
    margin-left: 15vw;
    margin-top: 10vh;
    max-width: 90%;

    h1 {
      color: App.$secondary-color;
      font-size: 7rem;
      letter-spacing: 2px;
      margin: 0;
      font-family: 'JetBrains Mono', monospace;
      font-weight: 800;
    }

    h2 {
      color: App.$secondary-color;
      font-size: 1.7rem;
      line-height: 2rem;
      margin-top: 20px;
      margin-left: 20px;
      font-family: 'Consolas', monospace;
      font-weight: 400;
      letter-spacing: 0.3rem;
    }

    @media (max-width: 1000px) {
      margin-top: 15vh;
    }
  }

  .flat-button {
    color: App.$secondary-color;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
    font-family: 'JetBrains Mono', monospace;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid App.$secondary-color;
    float: left;
    white-space: nowrap;

    &:hover {
      background-color: $hoverColor;
      border: 1px solid $hoverColor;
      color: #ffffff;
    }
  }

  .work-container {
    position: relative;
    top: 10vh;
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 8vw;

    @media (max-width: 700px) {
      margin: auto;
    }
  }

  .work-divider {
    width: 85%;
    margin: auto;
    text-align: center;
    height: 1px;
    background-color: App.$secondary-color;
    transform: translateX(4%);
    margin-top: 8vh;
  }

  .work-title {
    width: 100%;
    margin: auto;
    font-size: 1.5vw;
    text-align: center;
    margin-top: 4vh;
    margin-bottom: -4vh;
    font-family: 'Roboto mono';
    letter-spacing: 2px;
    font-weight: 1000;
  }
}
