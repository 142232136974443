@use '../../App';

.contact-page {
  .container {
    display: flex;
  }
}

.text-zone {
  width: 100%;

  h1 {
    font-size: 3rem;
    @media (max-width: 1000px) {
      font-size: 20px;
      letter-spacing: 2px;
    }
    // letter-spacing: 0.2rem;
    line-height: 5rem;
  }
}

#email-text {
  color: App.$active-color;

  &:hover {
    color: App.$secondary-color;
  }
}
