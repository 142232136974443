@use '../../App';

.page {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
}

.top-tags {
  bottom: auto;
  top: 35px;
}

.tags {
  //   font-weight: 500;
  color: #ff472f;
  opacity: 0.6;
  position: absolute;
  bottom: 20px;
  left: 220px;
  font-size: 20px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bottom-tag-html {
  margin-left: -20px;
}

.container {
  display: grid;
  width: 100vw;
  will-change: contents;
  height: 90%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
}

@media (max-width: 1000px) {
  .container {
    margin-top: 10vh;
  }
}

.text-zone {
  margin: auto 10vw;
  width: 20vw;
  vertical-align: middle;
  display: table-cell;
  max-height: 90%;
  color: App.$active-color;

  font-size: 1.5rem;
  line-height: 2rem;

  h1 {
    font-weight: 500;
    font-size: 2rem;
    font-family: 'Paytone One', sans-serif;
    color: App.$secondary-color;
  }
}
