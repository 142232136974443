.logo-container {
  z-index: -1;
  width: 20vw;
  position: absolute;
  top: 10vh;
  right: 10vw;
  left: auto;
  margin: auto;
  height: 5vh;

  @media (max-width: 1000px) {
    top: 15vh;
    right: 5vw;
  }

  .outline-logo {
    width: 100%;
    height: auto;
    z-index: 4;
    bottom: 0;
    // transform: rotateZ(30deg) !important;
  }

  .solid-logo {
    position: absolute;

    top: auto;
    left: auto;
    bottom: auto;
    margin: auto;
    left: 0;
    width: 100%;
    opacity: 0;
    z-index: -1;
  }
}

.svg-container {
  stroke: #222;
  stroke-width: 10px;
}

.logo-name,
.logo-outline {
  fill: none;
  stroke: #231f20;
  stroke: #ed8f85;
  stroke-miterlimit: 1;
  stroke-width: 3.5px;
  z-index: -1;
}

.logo-outline {
  stroke-width: 8px;
}
