.about-page {
  .container {
    display: flex;
  }

  .text-zone {
    margin-left: 12vw;
    margin-top: 3vh;
    h1 {
      font-size: 8rem;
    }
  }

  .text-content {
    font-size: 2.2rem;
    margin-left: 15px;
    font-weight: 300;
  }
}
