@use '../../App';

.nav-bar {
  background-color: #ededed;
  // box-shadow: #d2d2d2 1px 0px 10px 0px;
  width: 8vw;
  position: -webkit-fixed;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 3;
  min-height: 500px;
  height: 100vh;

  @media (max-width: 1000px) {
    width: 100vw;
    height: 10vh;
    min-height: 1px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .logo {
    display: block;
    width: auto;
    height: auto;
    padding: 8px;

    @media (max-width: 1000px) {
      padding: 0px 5px;
      margin: auto;
      height: 75%;
      display: inline-block;
    }

    img {
      width: 100%;
      height: 100%;

      @media (max-width: 1000px) {
        max-width: 15vw;
        width: auto;
        height: 100%;
      }
    }
  }

  nav {
    text-align: center;
    height: 210px;
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;

    @media (max-width: 1000px) {
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    a {
      color: App.$primary-color;
      font-size: 2vw;
      display: block;
      text-decoration: none;
      margin-bottom: 4vh;

      @media (max-width: 1000px) {
        width: 10vw;
        font-size: 2.5vh;
        float: left;
        display: block;
        padding: 0px 10px;
        line-height: auto;
        margin: auto;
      }

      i {
        transition: opacity 0.3s ease-out;
      }

      &:hover {
        color: App.$primary-color;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        transform: translateY(-3vh);
        content: '';
        font-size: 1vw;

        @media (max-width: 1000px) {
          font-size: 1vw;
        }

        letter-spacing: 2px;
        display: block;
        height: 100%;
        justify-content: center;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: opacity 0.3s ease-out;
      }

      &:first-child {
        margin-top: 4vh;

        @media (max-width: 1000px) {
          margin: auto;
        }

        &::after {
          content: 'HOME';
        }
      }
    }
    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.active {
      svg {
        color: App.$active-color;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    @media (max-width: 1000px) {
      bottom: 0;
      left: 90%;
      width: 10vw;
      height: 100%;
    }

    li {
      height: 100%;
      a {
        padding: 3vh 0;
        display: block;
        font-size: 2.5vw;
        line-height: 2.5vw;

        @media (max-width: 1000px) {
          height: 100%;
          font-size: 4vh;
        }

        &:hover svg {
          color: App.$highlight-color;
        }
      }
    }
  }
}
