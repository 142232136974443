.redact {
  background-color: white;

  .inner-box {
    box-shadow: none;
    background-color: white;
    background-image: url('https://redact.dev/images/redact-white.gif');
    .text-container {
      color: black;
      text-shadow: none;
    }
  }
}
